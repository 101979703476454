import * as React from "react";
import styled from "styled-components";
import { TextCycler, AppTitle, AppText, Wrapper, AppButton } from "../../ui";
import { Link, navigate } from "gatsby";
import theme from "../../../util/theme";
import { mobileSize, laptopSize } from "../../../util/variables";
import downArrow from "../../../content/img/icon/down-arrow.png";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import { track_hompage_get_started_click } from "../../../services/mixpanel/mixpanel";
import { AppOfTheYearAwards } from "./AppOfTheYearAwards";

const strings = [
  "Psychology",
  "Leadership",
  "Economics",
  "History",
  "Astrophysics",
  "Productivity",
];

const colors = ["#F9A220", "#247991", "#28BDA4", "#5095EB", "#5863D3", `${theme.YELLOW}`];

export const handleGetStarted = () => {
  localStorage.setItem("flow", "homepage");
  track_hompage_get_started_click();
  navigate("/onboarding/questions", {
    state: { fromHomepage: window.location.pathname === "/" },
  });
};

const IndexHeroSegment: React.FC = React.memo(() => {
  const [arrayIndex, setArrayIndex] = React.useState(0);

  React.useEffect(() => {
    let nextIndex = arrayIndex;
    if (nextIndex >= 5) {
      nextIndex = 0;
    } else {
      nextIndex++;
    }

    const interval = setInterval(() => setArrayIndex(nextIndex), 4000);
    return () => {
      clearInterval(interval);
    };
  }, [arrayIndex]);

  const dataHero = useStaticQuery(graphql`
    query MobileHeroImageQuery {
      heroMobile: file(relativePath: { eq: "homepage/hero-image-mobile.webp" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      heroDesktop: file(relativePath: { eq: "homepage/hero-image-desktop.webp" }) {
        childImageSharp {
          fixed(quality: 100, width: 900, height: 1200) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }
  `);

  return (
    <HeroContainer>
      <HeroWrapper>
        <HeroWrapperInner>
          <Wrapper>
            <HeroImgDiv>
              <Img
                fixed={dataHero.heroDesktop.childImageSharp.fixed}
                style={{
                  maxHeight: "100%",
                  top: 0,
                  bottom: 0,
                }}
                imgStyle={{
                  objectFit: "contain",
                  objectPosition: "right",
                }}
                alt="Hero image"
                loading="eager"
              />
            </HeroImgDiv>
            {/* <AbsImg src={heroDesktop} /> */}
            <HeroContent>
              <LargeHeading>
                See <br />{" "}
                <span>
                  <TextCycler
                    changeFrom={arrayIndex === 0 ? strings[5] : strings[arrayIndex - 1]}
                    text={strings[arrayIndex]}
                    strings={strings}
                    color={colors[arrayIndex]}
                  />
                </span>{" "}
                <br /> in a whole new <br /> way.
              </LargeHeading>
              <AppText style={{ maxWidth: 400 }}>
                The world’s most important knowledge, visualized.
              </AppText>
              {/* <DownloadBadges /> */}
              <AppOfTheYearAwards />
              <AppButton style={{ marginTop: 30 }} onClick={handleGetStarted}>
                Get Started
              </AppButton>
            </HeroContent>
            {/* <MobileBlurDiv /> */}
          </Wrapper>
          <MobileHeroDiv>
            <Img
              fluid={dataHero.heroMobile.childImageSharp.fluid}
              style={{ minHeight: "auto" }}
              imgStyle={{
                objectFit: "cover",
                objectPosition: "center",
              }}
              alt="Hero image mobile"
              loading="eager"
            />
          </MobileHeroDiv>
          {/* <AbsMobileImg src={heroMobile} /> */}
        </HeroWrapperInner>
      </HeroWrapper>
      <ContinueLink to="/#more">
        <AppText noMargin fontSize={14} color={theme.PRIMARY_COLOR}>
          Continue
        </AppText>
        <DownArrow />
      </ContinueLink>
    </HeroContainer>
  );
});

const MobileHeroDiv = styled.div`
  display: none;
  @media ${mobileSize} {
    display: block;
    margin-top: 20px;
    min-height: 50vh;
    width: 100%;
  }
`;

const ContinueLink = styled(Link)`
  position: sticky;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  left: 50%;
  bottom: 24px;
  z-index: 4;
  margin-bottom: 20px;
  @media ${mobileSize} {
    display: none;
  }
`;

const DownArrow = styled.div`
  height: 12px;
  width: 22px;
  background-color: ${theme.PRIMARY_COLOR};
  mask-image: url(${downArrow});
  mask-size: contain;
  mask-position: center;
  mask-repeat: no-repeat;
`;

const HeroImgDiv = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  min-height: 100%;
  max-width: 90vw;
  @media ${laptopSize} {
    max-width: 100vw;
  }
  @media ${mobileSize} {
    display: none;
  }
`;

const LargeHeading = styled(AppTitle as any)`
  margin-bottom: 28px;
  font-size: 84px;
  line-height: 1;
  @media ${laptopSize} {
    margin-bottom: 36px;
    font-size: 72px;
    line-height: 74px;
  }
  @media ${mobileSize} {
    margin-bottom: 24px;
    font-size: 52px;
    line-height: 52px;
  }
`;

const HeroContainer = styled.div`
  background-color: ${theme.WHITE_COLOR};
  background-size: cover;
  overflow: hidden;
  /* overflow: auto; */
  position: relative;
  width: 100%;
  -webkit-transition: all 500ms ease-in-out;
  -moz-transition: all 500ms ease-in-out;
  -o-transition: all 500ms ease-in-out;
  -ms-transition: all 500ms ease-in-out;
  transition: all 500ms ease-in-out;
  /* For the sticky nav bar */
  margin-top: -140px;
  @media ${mobileSize} {
    height: auto;
    /* For the sticky nav bar */
    margin-top: -120px;
  }
`;

const HeroWrapper = styled.div`
  display: table;
  height: 100%;
  width: 100%;
`;

const HeroWrapperInner = styled.div`
  position: relative;
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  @media ${mobileSize} {
    padding-top: 120px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
`;

const HeroContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  z-index: 2;
  width: 70%;
  margin: 124px 0;
  /* float: left; */
  @media ${laptopSize} {
    width: 60%;
  }
  @media ${mobileSize} {
    margin: 0;
    width: 100%;
    min-width: 90vw;
  }
`;

export { IndexHeroSegment };
