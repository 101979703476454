import * as React from "react";
import styled from "styled-components";
import { AppTitle, AppText } from "../../ui";
import theme from "../../../util/theme";
import { mobileSize } from "../../../util/variables";
import { OutboundLink } from "gatsby-plugin-google-gtag";
import { teamMembers } from "./teamMembers";
import linkedInLogo from "../../../content/img/icon/linkedin.png";

interface TeamData {
  name: string;
  url: string;
  image: string;
  title: string;
  bio: string;
}

const OurTeamSegment: React.FC = React.memo(() => {
  return (
    <ContainerDiv>
      <ContentDiv>
        <WhiteAppTitle>Our Team</WhiteAppTitle>
        <FlexDiv>
          {teamMembers.map((item: TeamData) => (
            <MemberDiv key={item.name.replace(" ", "")}>
              <OverviewDiv isFounder={item.name === "Daniel Terry"}>
                <MemberImg src={`/${item.image}`} alt={item.name} />
                <MemberName>{item.name}</MemberName>
                <MemberTitle>{item.title}</MemberTitle>
              </OverviewDiv>
              <BioDiv>
                <SmallOutboundLink href={item.url} target="_blank" rel="noopener noreferrer">
                  <LinkedInImg src={linkedInLogo} alt={`${item.name} LinkedIn`} />
                </SmallOutboundLink>
                <BioText>{item.bio}</BioText>
              </BioDiv>
            </MemberDiv>
          ))}
        </FlexDiv>
      </ContentDiv>
    </ContainerDiv>
  );
});

const SmallOutboundLink = styled(OutboundLink)`
  display: inline-block;
  width: fit-content;
  line-height: 1;
  @media ${mobileSize} {
    margin: 0px auto;
  }
`;

const MemberName = styled(AppText as any)`
  font-family: ${theme.PRIMARY_FONT};
  font-size: 18px;
  line-height: 28px;
  margin: 0px;
  margin-top: 16px;
  text-align: center;
  @media ${mobileSize} {
    margin-top: 20px;
  }
`;

const MemberTitle = styled(AppText as any)`
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  max-width: 95%;
  color: ${theme.PRIMARY_COLOR};
  text-transform: uppercase;
  margin: 0px;
  text-align: center;
`;

interface IsFounderProp {
  isFounder?: boolean;
}

const OverviewDiv = styled.div<IsFounderProp>`
  display: flex;
  flex-direction: column;
  width: 218px;
  align-items: center;
  justify-content: ${props => (props.isFounder ? "start" : "center")};
  margin-right: 9px;
  @media ${mobileSize} {
    width: 100%;
    margin: 0px auto;
  }
`;

const BioDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 314px;
  justify-content: center;
  @media ${mobileSize} {
    width: 100%;
  }
`;

const BioText = styled(AppText as any)`
  font-size: 14px;
  line-height: 18px;
  color: ${theme.BLACK_COLOR};
  margin: 0px;
  margin-top: 8px;
  @media ${mobileSize} {
    margin-top: 16px;
  }
`;

const LinkedInImg = styled.img`
  width: 16px;
  height: 16px;
`;

const MemberImg = styled.img`
  object-fit: cover;
  height: 120px;
  width: 120px;
  border-radius: 50%;
`;

const MemberDiv = styled.div`
  padding: 32px 40px;
  padding-left: 14px;
  border-radius: 16px;
  background-color: ${theme.WHITE_COLOR};
  margin-top: 24px;
  display: flex;
  min-height: 272px;
  @media ${mobileSize} {
    width: calc(100%);
    flex-direction: column;
    padding: 32px 40px;
  }
`;

const FlexDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

const WhiteAppTitle = styled(AppTitle as any)`
  color: ${theme.BLACK_COLOR};
  width: 100%;
  margin-bottom: 16px;
`;

const ContainerDiv = styled.div`
  background: ${theme.GREY_COLOR};
  width: 100%;
`;

const ContentDiv = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  margin: 80px auto;
  max-width: 588px;
  @media ${mobileSize} {
    padding-left: 24px;
    padding-right: 24px;
    margin: 40px auto 60px auto;
    flex-direction: column;
  }
`;
export { OurTeamSegment };
