import * as React from "react";
import styled from "styled-components";
import { AppTitle, AppText } from "../../ui";
import theme from "../../../util/theme";
import { mobileSize } from "../../../util/variables";

const WeAreImprintSegment: React.FC = React.memo(() => {
  return (
    <ContainerDiv>
      <ContentDiv>
        <AppTitle color={theme.WHITE_COLOR}>Learn visually, with Imprint.</AppTitle>
        <AppText color={theme.WHITE_COLOR}>
          Our mission is to make the world&apos;s most important knowledge easy to learn, internalize, and apply to your life.
          By visualizing and clarifying complex insights from the world&apos;s greatest thinkers, we&apos;re helping people around
          the world master essential topics and learn new skills, quickly and easily.
        </AppText>
      </ContentDiv>
    </ContainerDiv>
  );
});

const ContainerDiv = styled.div`
  background: ${theme.PRIMARY_COLOR};
  width: 100%;
`;

const ContentDiv = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  margin: 100px auto;
  max-width: 588px;
  @media ${mobileSize} {    
    padding: 0 20px;
    margin: 60px auto;

    flex-direction: column;
  }
`;

export { WeAreImprintSegment };
