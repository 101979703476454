export const teamMembers = [
  {
    name: "Daniel Terry",
    url: "https://www.linkedin.com/in/daniel-terry-18a9031/",
    image: "daniel.png",
    title: "Founder",
    bio:
      "Daniel is Imprint's Founder, Executive Chairman, and Chief Innovation Officer. He is also the Founder, Chairman and former CEO of Pocket Gems and Episode Interactive, leading mobile app companies that have each reached hundreds of millions of people and collectively raised over $150M from Sequoia Capital and Tencent. Daniel founded Pocket Gems in 2009 where he built a team of hundreds and led the company to release dozens of mobile gaming titles, establish multiple new breakout gaming categories and consistently maintain top grossing positions on mobile app stores for over a decade. In 2014, Daniel founded Episode Interactive which immediately defined the now billion dollar market for mobile interactive fiction where it remains the category leader with over 5 million weekly active readers and over 10 million registered content writers. Daniel holds a BA in computer science from Cornell and an MBA from Stanford.",
  },
  {
    name: "Jeff Feldman",
    url: "https://www.linkedin.com/in/jfeld/",
    image: "jeff.png",
    title: "CEO",
    bio:
      "Jeff previously held product leadership roles at Resy (acquired by American Express) and Pronoun (acquired by Macmillan). Before that, he worked on enterprise products at Google. He has a BA in history and computer science from Harvard.",
  },
  {
    name: "Crosby Ignasher",
    url: "https://www.linkedin.com/in/crosby-ignasher-85448075/",
    image: "crosby.png",
    title: "Director of Animation",
    bio:
      "As a freelance animator, Crosby has worked with And / Or, Trollback, The Wall Street Journal and others. Before that, he was an animator at McCann, working on Mastercard and other accounts. He has a BFA in motion graphics from The Savannah College of Art and Design (SCAD).",
  },
  {
    name: "Olivia Kang",
    url: "https://www.linkedin.com/in/oliviakang/",
    image: "Kang.png",
    title: "Director of Learning",
    bio:
      "Olivia is a social scientist and learning architect. Her research investigates whether the eyes are truly \"windows to the soul,\" revealing when our minds wander, what engages attention, and the things we imagine. Previously, Olivia directed Mahzarin Banaji's Outsmarting Implicit Bias project, and taught in Harvard's psychology department. She holds a BA in psychology from Princeton and a PhD in brain sciences from Dartmouth.",
  },
  {
    name: "Maya Liebman",
    url: "https://www.linkedin.com/in/mayaliebman/",
    image: "maya.png",
    title: "ux Designer",
    bio:
      "Before joining the team at Imprint, Maya made the transition from recruiting to UX design to pair her creative passions and love of people. She has a BA in sociology and psychology from SUNY Geneseo and completed her education in UX design at DesignLab.",
  },
  {
    name: "Jasmyne Eastmond",
    url: "https://www.linkedin.com/in/jasmyne-eastmond-2219831aa/",
    image: "jasmyne.png",
    title: "Content Editor",
    bio:
      "Jasmyne has written stories on environmental justice and arts and culture for publications across Canada. Prior to joining Imprint, she worked as a  copy editor for FiveThirtyEight. She received her BSc in biology and English literature from the University of British Columbia and her M.Phil in English from the University of Cambridge.",
  },
  {
    name: "Conor O'Byrne",
    url: "https://www.linkedin.com/in/conor-o-byrne-b37840209/",
    image: "conor.jpeg",
    title: "Content Editor",
    bio:
      "Conor is a writer and editor based in Brooklyn, NY. His work often explores themes like queer identity and desire, connection and disconnection in urban spaces, and the speculative. He has an AB in English from Brown and an MFA in nonfiction writing from Columbia.",
  },
  {
    name: "Nick Sarno",
    url: "https://www.linkedin.com/in/nicholassarno/",
    image: "nick-sarno.jpeg",
    title: "Senior iOS Engineer",
    bio:
      "Nick has a passion for making beautiful iOS applications. He has worked on a wide range of projects, from online market places to social networks. Nick has published several online courses teaching students how to code in Swift and SwiftUI. He has a BS in Finance from Penn State.",
  },
  {
    name: "Jessica Johnson",
    url: "https://www.linkedin.com/in/jessjo/",
    image: "jessica-johnson.png",
    title: "Lead Product Manager",
    bio:
      "Jessica is a product manager with a passion for helping people read and learn. She has previously worked on both growth and retention with products both big and small at Scribd, Goodreads and Google. She once won a scholarship for being tall. She has a BA in English from Stanford.",
  },
  {
    name: "Sophia Hadley",
    url: "https://www.linkedin.com/in/sophiahadley/",
    image: "sophia-hadley.jpg",
    title: "Content Editor",
    bio:
      "Sophia Hadley is a writer, editor, and educator who is passionate about making humanities education more accessible to a broad audience. Her academic research is in nineteenth-century literature and history, but she enjoys learning and writing about a broad spectrum of subjects. She received her BA in English Literature from Biola University and her MA and PhD in British and American Literature from Boston University.",
  },
  {
    name: "Lara Fromherz",
    url: "https://www.linkedin.com/in/larafromherz/",
    image: "lara-fromherz.jpg",
    title: "Lifecycle Marketing Manager",
    bio:
      "Lara is passionate about empowering people and companies alike. She’s a lifecycle marketer who loves helping startups across industries build and retain their customer base. She has a BA in business from Boston University and lives in Brooklyn. She’s summited Mt. Kilimanjaro and thru-hiked the John Muir Trail and the Long Trail.",
  },
  {
    name: "Nate Kanefield",
    url: "https://www.linkedin.com/in/nathaniel-kanefield-a7aa9429/",
    image: "nate-kanefield.jpg",
    title: "Head of Growth",
    bio:
      "Nate is a growth leader passionate about bringing together great people and great products.  Prior to Imprint, Nate was the Head of Growth and Monetization at Flowcode, the Co-Founder and CEO of Minema, and worked as Chief of Staff to the CEO of AOL. Nate started his career as a management consultant at Oliver Wyman. Nate has an MBA from Stanford GSB and a BA in History and Economics from Dartmouth.",
  },
];
