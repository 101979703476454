import * as React from "react";
import styled from "styled-components";
import { AppTitle, AppText } from "../../ui";
import theme from "../../../util/theme";
import { mobileSize } from "../../../util/variables";
import { OutboundLink } from "gatsby-plugin-google-gtag";

const WorkHereSegment: React.FC = React.memo(() => {
  return (
    <ContainerDiv>
      <ContentDiv>
        <WhiteAppTitle>Want to work at Imprint?</WhiteAppTitle>
        <WhiteAppText>Learn about Imprint and our open positions.</WhiteAppText>
        <CenterDiv>
          <OutboundLink href="https://imprint.applytojob.com/" target="_blank" rel="noopener noreferrer">
            <DownloadButton>Explore Careers</DownloadButton>
          </OutboundLink>
        </CenterDiv>
      </ContentDiv>
    </ContainerDiv>
  );
});

const CenterDiv = styled.div`
  width: 100%;
`;

const DownloadButton = styled.button`
  width: 280px;
  height: 52px;
  border: 3px solid ${theme.WHITE_COLOR};
  border-radius: 32px;

  background: transparent;
  font-size: 17px;
  font-family: ${theme.SECONDARY_FONT};
  line-height: 20px;
  color: ${theme.WHITE_COLOR};
  @media ${mobileSize} {
    width: 100%;
  }
  :focus {
    outline: none;
  }
`;

const WhiteAppTitle = styled(AppTitle as any)`
  color: ${theme.WHITE_COLOR};
  width: 100%;
`;

const WhiteAppText = styled(AppText as any)`
  color: ${theme.WHITE_COLOR};
  width: 100%;
  @media ${mobileSize} {
    max-width: 100%;
  }
`;

const ContainerDiv = styled.div`
  background: ${theme.PRIMARY_COLOR};
  width: 100%;
`;

const ContentDiv = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  margin: 100px auto;
  max-width: 588px;
  @media ${mobileSize} {
    padding-left: 20px;
    padding-right: 20px;
    flex-direction: column;
  }
`;

export { WorkHereSegment };
